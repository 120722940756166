import React from "react";
import './MetaDataList.scss'

type Props = {
  data: Pwamap.ShopData;
};

const Content = (props: Props) => {

  return (
    <div className="metadata-container">
      {props.data['備考1']? <span className="nowrap"><span className="metadata">{props.data['備考1']}</span></span> : null}
      {props.data['備考2']? <span className="nowrap"><span className="metadata">{props.data['備考2']}</span></span> : null}
      {props.data['備考3']? <span className="nowrap"><span className="metadata">{props.data['備考3']}</span></span> : null}
      {props.data['備考4']? <span className="nowrap"><span className="metadata">{props.data['備考4']}</span></span> : null}
      {props.data['備考5']? <span className="nowrap"><span className="metadata">{props.data['備考5']}</span></span> : null}
      {props.data['備考6']? <span className="nowrap"><span className="metadata">{props.data['備考6']}</span></span> : null}
      {props.data['備考7']? <span className="nowrap"><span className="metadata">{props.data['備考7']}</span></span> : null}
      {props.data['備考8']? <span className="nowrap"><span className="metadata">{props.data['備考8']}</span></span> : null}
      {props.data['備考9']? <span className="nowrap"><span className="metadata">{props.data['備考9']}</span></span> : null}
      {props.data['備考10']? <span className="nowrap"><span className="metadata">{props.data['備考10']}</span></span> : null}
    </div>
  );
};

export default Content;
