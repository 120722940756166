type categoryProps = {
  [key: string]: string
}

export const categoryList:categoryProps = {
  odewan_all: '全てのスポット',
  odewan_cafe: '飲食店',
  odewan_dogrun: 'ドッグラン',
  odewan_hotel: '宿',
  odewan_zoo: '水族館・動物園',
  odewan_themepark: 'テーマパーク',
  odewan_shopping: 'ショッピング',
  odewan_michi_no_eki: '道の駅',
  odewan_play: 'その他'
}

const paint = {
  'text-color': '#000000',
  'text-halo-color': '#FFFFFF',
  'text-halo-width': 2,
}

const layout = {
  'text-field': "{スポット名}",
  'text-font': ['Noto Sans Regular'],
  'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
  'text-radial-offset': 1.5,
  'text-justify': 'auto',
  'text-size': 12,
  'text-anchor': 'top',
  'text-max-width': 12,
  'text-allow-overlap': true,
  'icon-allow-overlap': true,
  'visibility': 'none',
}

export const layers = Object.keys(categoryList).map((key) => {

  return {
    id: key,
    type: 'symbol',
    source: key,
    filter: ['==', ['get', 'カテゴリ'], categoryList[key]],
    paint,
    layout: {
      ...layout,
      'icon-image': key,
    },
  }

})

export const odewan_all_layers = Object.keys(categoryList).map((key) => {

  return {
    id: `${key}_all`,
    type: 'symbol',
    source: 'odewan_all',
    filter: ['==', ['get', 'カテゴリ'], categoryList[key]],
    paint,
    layout: {
      ...layout,
      'icon-image': key,
    },
  }

})
