import React from "react";
import hidePoiLayers from './hidePoiLayers'
import Shop from './Shop'
import { layers, odewan_all_layers, categoryList } from './styles'
import LogoControl from './LogoControl'
import toGeoJson from './toGeoJson'

type Props = {
  data: Pwamap.ShopData[];
};

const CSS: React.CSSProperties = {
  width: '100%',
  height: '100%',
  position: 'relative',
}

const SelectCSS: React.CSSProperties = {
  position: 'absolute',
  top: 0,
  zIndex: 2,
  margin: '10px',
  padding: '3px 5px',
  borderRadius: '5px'
}

const filterSourceData = (data: Pwamap.ShopData[], category: string) => {

  if (category === 'odewan_all') {
    return data
  }

  return data.filter((shop) => {
    return shop["カテゴリ"] === categoryList[`${category}`]
  })
}

const addSources = (map: any, data: Pwamap.ShopData[]) => {

  Object.keys(categoryList).forEach((category) => {

    const filteredGeoJSON = toGeoJson(filterSourceData(data, category))

    if (!map.getSource(category)) {

      map.addSource(category, {
        type: 'geojson',
        data: filteredGeoJSON,
        cluster: true,
        clusterMaxZoom: 14,
        clusterRadius: 25,
      })
    }
  });
}

const addCluster = (map: any, layerId: string) => {

  map.addLayer({
    id: `${layerId}-cluster`,
    type: 'circle',
    source: layerId,
    filter: ['has', 'point_count'],
    paint: {
      'circle-radius': 20,
      'circle-color': '#FF0000',
      'circle-opacity': 1.0,
    },
    layout: {
      'visibility': 'none'
    }
  })

  map.addLayer({
    id: `${layerId}-cluster-count`,
    type: 'symbol',
    source: layerId,
    filter: ['has', 'point_count'],
    paint: {
      'text-color': '#FFFFFF',
    },
    layout: {
      'text-field': '{point_count_abbreviated} 件',
      'text-size': 12,
      'text-font': ['Noto Sans Regular'],
      'visibility': 'none'
    },
  })

  map.on('click', `${layerId}-cluster`, (e: any) => {
    console.log(`${layerId}-cluster`)
    const features = map.queryRenderedFeatures(e.point, { layers: [`${layerId}-cluster`] })
    const clusterId = features[0].properties.cluster_id
    map.getSource(layerId).getClusterExpansionZoom(clusterId, (err: any, zoom: number) => {
      if (err)
        return

      map.easeTo({
        center: features[0].geometry.coordinates,
        zoom: zoom + 1,
      })
    })
  })

  map.on('mouseenter', `${layerId}-cluster`, function () {
    console.log(`${layerId}-cluster`)
    map.getCanvas().style.cursor = 'pointer'
  })

  map.on('mouseleave', `${layerId}-cluster`, function () {
    map.getCanvas().style.cursor = ''
  })
}

const addMarkersLayer = (map: any, setShop: any) => {

  const openShopDetail = (event: any) => {

    if (!event.features[0].properties.cluster) {
      setShop(event.features[0].properties)
    }
  }

  layers.forEach((layer: any) => {

    if (!map.getLayer(layer.id)) {

      if (layer.id === 'odewan_all') {

        odewan_all_layers.forEach((layer: any) => {
          map.addLayer(layer)
          map.on('click', layer.id, openShopDetail)
        })

      } else {
        map.addLayer(layer)
      }

      addCluster(map, layer.id)

      map.on('click', layer.id, openShopDetail)

      map.on('mouseenter', layer.id, () => {
        map.getCanvas().style.cursor = 'pointer'
      })

      map.on('mouseleave', layer.id, () => {
        map.getCanvas().style.cursor = ''
      })
    }

  })
}

const onChangeMarker = (category: any, map: any) => {

  layers.forEach((layer: any) => {

    if (category === layer.id) {

      if (category === 'odewan_all' && layer.id === 'odewan_all') {

        layers.forEach((layer:any) => {
          map.setLayoutProperty(`${layer.id}_all`, 'visibility', 'visible')
        })

        map.setLayoutProperty(`odewan_all-cluster`, 'visibility', 'visible')
        map.setLayoutProperty(`odewan_all-cluster-count`, 'visibility', 'visible')

      } else {

        map.setLayoutProperty(layer.id, 'visibility', 'visible')
        map.setLayoutProperty(`${layer.id}-cluster`, 'visibility', 'visible')
        map.setLayoutProperty(`${layer.id}-cluster-count`, 'visibility', 'visible')

        layers.forEach((layer:any) => {
          map.setLayoutProperty(`${layer.id}_all`, 'visibility', 'none')
        })
      }

    } else if (category !== layer.id) {

      map.setLayoutProperty(layer.id, 'visibility', 'none')
      map.setLayoutProperty(`${layer.id}-cluster`, 'visibility', 'none')
      map.setLayoutProperty(`${layer.id}-cluster-count`, 'visibility', 'none')

    }
  })
}

const Content = (props: Props) => {
  const mapNode = React.useRef<HTMLDivElement>(null);
  const selectRef = React.useRef<HTMLSelectElement>(null);
  const [mapObject, setMapObject] = React.useState<any>()
  const [shop, setShop] = React.useState<Pwamap.ShopData | undefined>(undefined)

  React.useEffect(() => {
    // Only once reder the map.
    if (!mapNode.current || mapObject) {
      return
    }

    // @ts-ignore
    const { geolonia } = window;

    const map = new geolonia.Map({
      container: mapNode.current,
      style: 'geolonia/gsi',
      center: [137.44, 39.21],
      zoom: 3.83,
    });

    const onMapLoad = () => {

      hidePoiLayers(map)
      setMapObject(map)

      const logoControl = new LogoControl();
      map.addControl(logoControl, 'bottom-left');
    }

    const orienteationchangeHandler = () => {
      map.resize()
    }

    // attach
    map.on('load', onMapLoad)

    window.addEventListener('orientationchange', orienteationchangeHandler)

    return () => {
      // detach to prevent memory leak
      window.removeEventListener('orientationchange', orienteationchangeHandler)
      map.off('load', onMapLoad)
    }
  }, [mapNode, mapObject, props.data])

  React.useEffect(() => {

    if (!mapObject || !props.data || props.data.length === 0) {
      return
    }

    addSources(mapObject, props.data)
    addMarkersLayer(mapObject, setShop)
    onChangeMarker(selectRef.current?.value, mapObject)

  }, [mapObject, props.data, shop])

  const closeHandler = () => {
    setShop(undefined)
  }

  return (
    <div style={CSS}>
      <select
        ref={selectRef}
        name="category"
        id="category-select"
        style={SelectCSS}
        defaultValue="odewan_all"
        onChange={(e) => onChangeMarker(e.target.value, mapObject)}
      >
        <option value="odewan_all">全てのスポット</option>
        <option value="odewan_cafe">飲食店</option>
        <option value="odewan_dogrun">ドッグラン</option>
        <option value="odewan_hotel">宿</option>
        <option value="odewan_zoo">水族館・動物園</option>
        <option value="odewan_themepark">テーマパーク</option>
        <option value="odewan_shopping">ショッピング</option>
        <option value="odewan_michi_no_eki">道の駅</option>
        <option value="odewan_play">その他</option>
      </select>
      <div
        ref={mapNode}
        style={CSS}
        data-geolocate-control="on"
        data-marker="off"
        data-gesture-handling="off"
      ></div>
      {shop ?
        <Shop shop={shop} close={closeHandler} />
        :
        <></>
      }
    </div>
  );
};

export default Content;
