class LogoControl {

  map: any;
  container: HTMLDivElement | undefined;

  onAdd(map: any){

    this.map = map;

    this.container = document.createElement('div');
    this.container.className = 'mapboxgl-ctrl';
    this.container.style.margin = '0 0 5px 0';

    const link = document.createElement('a');
    link.href = 'https://odekake-wanko-bu.com';

    const img = document.createElement('img');
    img.src = `${process.env.PUBLIC_URL}/logo-small.png`;
    img.alt = 'おでかけわんこ部';

    link.appendChild(img);
    this.container.appendChild(link);

    return this.container;

  }

  onRemove(){

    if (this.container && this.container.parentNode) {

      this.container.parentNode.removeChild(this.container);
      this.container = undefined;

    }

  }
}

export default LogoControl;
